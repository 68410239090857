import menu from "../assets/images/KapoleiMenu.jpg";
import FancyBox from "./FancyBox";
import { Helmet } from "react-helmet";
import turtles from "../assets/images/turtles.png";
import Button from "react-bootstrap/Button";

function Menu() {
  <FancyBox />;
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo & Coffee Bar Kapolei | Menu</title>
      </Helmet>
      <div className="container-lg mt-60 mb-60">
        <div className="row justify-content-center">
          <div className="section_title text-center pb-5">
            <h4 className="title">Menu</h4>
            <img className="turtles" alt="turtle pic" src={turtles} />
          </div>
        </div>
        <div className="d-flex justify-content-center pb-4">
          <Button
            size="lg"
            href="https://order.online/store/sacred-art-tattoo-and-coffee-kapolei-24799086/?hideModal=true&pickup=true"
            variant="success"
          >
            Order Online
          </Button>
        </div>
        <div className="row text-center">
          <a data-fancybox data-src={menu} href="#0">
            <img
              src={menu}
              className="img-thumbnail w-100"
              style={{ maxWidth: "988px" }}
              alt="menu"
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default Menu;
