import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import logo from "../assets/images/sacred-art-logo.png";
import book from "../assets/images/booknow.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import "../assets/css/header.css";
import NavDropdown from "react-bootstrap/NavDropdown";

function Header() {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Navbar
        expanded={expanded}
        collapseOnSelect
        bg="dark"
        variant="dark"
        sticky="top"
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand
            as={Link}
            to="/"
            onClick={() => setExpanded(false)}
            className="leftBrand"
          >
            <img src={logo} className="leftLogo" alt="logo pic" />
            <div className="navText">~World Famous since 1992~</div>
          </Navbar.Brand>
          <div>
            <Link
              className="bookNow2Link"
              onClick={() => setExpanded(false)}
              to="/contact"
            >
              <img src={book} alt="book now" className="bookNow2" />
            </Link>
            <Navbar.Toggle
              onClick={() => setExpanded(expanded ? false : "expanded")}
              aria-controls="responsive-navbar-nav"
            />
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="nav-left">
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link"
                to="/about"
              >
                Our Story
              </Link>
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link"
                to="/services"
              >
                Services
              </Link>
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link"
                to="/artists"
              >
                Our Artists
              </Link>
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link"
                to="/gallery"
              >
                Gallery
              </Link>
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link"
                to="/menu"
              >
                Menu
              </Link>
            </Nav>
            <Link className="middleBrand text-center" to="/">
              <img className="mainLogo" src={logo} alt="logo pic"></img>
              <p>~World Famous since 1992~</p>
            </Link>
            <Nav className="nav-right">
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link"
                to="/specials"
              >
                Flash Specials
              </Link>
              <NavDropdown title="Locations" id="locations-dropdown">
                <NavDropdown.Item
                  className="locations-dropdown"
                  href="https://sacredartkailua.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Kailua
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="locations-dropdown"
                  href="https://sacredartwaikiki.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Waikiki
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="locations-dropdown"
                  href="https://sacredarthonolulu.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Kalakaua
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="locations-dropdown"
                  href="https://sacredartkapolei.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Kapolei
                </NavDropdown.Item>
              </NavDropdown>
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link"
                to="/faq"
              >
                FAQ
              </Link>
              <a
                className="nav-link"
                href="https://squareup.com/gift/ML99NPNT3X91W/order"
                target="_blank"
                rel="noreferrer noopener"
              >
                Buy a Gift Card
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Link onClick={() => setExpanded(false)} to="/contact">
          <img src={book} alt="book now" className="bookNow" />
        </Link>
      </Navbar>
    </>
  );
}

export default Header;
